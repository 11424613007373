import * as React from "react";
import Layout from "../components/layout";
import Historico from "../components/pagecomponents/historico";
import PageComponentLayout from "../components/pagecomponents/pagecomponentlayout";
import Seo from "../components/utils/seo";

const HistoricoPage = () => {
  return (
    <Layout>
      <PageComponentLayout title="Histórico">
        <Historico />
      </PageComponentLayout>
    </Layout>
  );
};

export default HistoricoPage;

export const Head = () => <Seo title="Histórico" />
