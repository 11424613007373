import * as React from "react";
import * as styles from './historico.module.css';

const Historico = () => {
  return (
    <div className={styles.historicoContainer}>
      <p>Run Santa Run es la carrera de Santas pionera en México. Somos un evento deportivo con temática navideña que se lleva a cabo en Monterrey desde el 2016, y Guadalajara desde el 2018.</p>
      <p>Una ruta llena de sorpresas, ambientación navideña, encuentros cercanos con el Grinch y nieve artificial cayendo del cielo son algunas vivencias que destacan en la carrera más grande de Santas en México.</p>
      <p>Run Santa Run cuenta con un programa llamado Corriendo por Una Sonrisa, en el que, al comprar tu boleto de la carrera, estarás participando en la compra de juguetes para llevarlos como regalo de Santa Claus en Navidad a los niños de zonas marginadas y de escasos recursos.</p>
    </div>
  );
};

export default Historico;
